import React from 'react';
import Slider from "react-slick";
import testimonial1 from '../img/testimonial-1.jpg';
import testimonial2 from '../img/testimonial-2.jpg';
import testimonial3 from '../img/testimonial-3.jpg';
import testimonial4 from '../img/testimonial-4.jpg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">Testimonial</p>
                    <h1 className="mb-5">What People Say About Our Inverter & Battery Products</h1>
                </div>
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="testimonial-img">
                            <img className="img-fluid animated pulse infinite" src={testimonial1} alt="" />
                            <img className="img-fluid animated pulse infinite" src={testimonial2} alt="" />
                            <img className="img-fluid animated pulse infinite" src={testimonial3} alt="" />
                            <img className="img-fluid animated pulse infinite" src={testimonial4} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
                        <Slider {...settings}>
                            <div className="testimonial-item">
                                <img className="img-fluid mb-3" src={testimonial1} alt="" />
                                <p className="fs-5">Purchased an inverter and battery combo for my home, and it works flawlessly. The installation team was efficient and clean. I appreciate the post-purchase support they offer too. If you need reliable power solutions, this is the place to go!</p>
                                <h5>Prathik</h5>
                            </div>
                            <div className="testimonial-item">
                                <img className="img-fluid mb-3" src={testimonial2} alt="" />
                                <p className="fs-5">I had a wonderful experience at this shop! Karthik was patient and answered all my questions about inverters and batteries. He even followed up after my purchase to ensure everything was working well. It’s rare to find such dedication to customer satisfaction!</p>
                                <h5>Madhu</h5>
                            </div>
                            <div className="testimonial-item">
                                <img className="img-fluid mb-3" src={testimonial3} alt="" />
                                <p className="fs-5">This shop offers fantastic prices on inverters and batteries. I shopped around, and their deals were unbeatable. The product quality is excellent, and the staff was friendly and helpful. I’m thrilled with my purchase and will recommend them to friends and family!</p>
                                <h5>Keshava</h5>
                            </div>
                            <div className="testimonial-item">
                                <img className="img-fluid mb-3" src={testimonial4} alt="" />
                                <p className="fs-5">I bought a battery for my solar system here, and it has been a game changer! The quality is top-notch, and I've noticed a significant improvement in efficiency. Plus, the customer service was outstanding—they took the time to explain everything. Will definitely be back!</p>
                                <h5>Karuna</h5>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
