import React from 'react';
import service1 from '../img/batteryreconditioning.webp';
import service2 from '../img/batteryreplacement.webp';
import service3 from '../img/instoreservice.webp';
import service4 from '../img/invertertroubleshoot.png';
import service5 from '../img/inverterservice.jpg';

const Services = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">Our Services</p>
                    <h1 className="mb-5">Services That We Offer For Customers</h1>
                </div>
                <div className="row gy-5 gx-4">
                    <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item d-flex h-100">
                            <div className="service-img">
                                <img className="img-fluid" src={service4} alt="Best Animal Selection" />
                            </div>
                            <div className="service-text p-5 pt-0">
                                <div className="service-icon">
                                    <img className="img-fluid rounded-circle" src={service4} alt="Best Animal Selection" />
                                </div>
                                <h5 className="mb-3">Residential Inverter Installation</h5>
                                <p><ul  className="mb-4"><li><b>Energy Assessment:</b> We evaluate your home’s energy needs to recommend the best inverter system.</li></ul></p>
                                <p><ul  className="mb-4"><li>Custom Solutions: Options include solar, grid-tie, and off-grid inverters tailored to your budget.</li></ul></p>
                                <p><ul  className="mb-4"><li>Professional Installation: Certified technicians ensure safe and efficient setup.</li></ul></p>
                                <p><ul  className="mb-4"><li>Ongoing Support: Continuous maintenance to keep your system running smoothly.</li></ul></p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item d-flex h-100">
                            <div className="service-img">
                                <img className="img-fluid" src={service5} alt="Best Animal Selection" />
                            </div>
                            <div className="service-text p-5 pt-0">
                                <div className="service-icon">
                                    <img className="img-fluid rounded-circle" src={service5} alt="Best Animal Selection" />
                                </div>
                                <h5 className="mb-3">Commercial Inverter Installation</h5>
                                <p><ul  className="mb-4"><li><b>Tailored Solutions: </b> We provide inverter systems designed for your business's energy demands.</li></ul></p>
                                <p><ul  className="mb-4"><li><b>Site Assessments: </b>Detailed evaluations to find the right system for your premises.</li></ul></p>
                                <p><ul  className="mb-4"><li><b>Scalable Options: </b> Solutions that grow with your business.</li></ul></p>
                                <p><ul  className="mb-4"><li><b>Expert Installation:  </b> Minimal disruption during the setup process.</li></ul></p>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item d-flex h-100">
                            <div className="service-img">
                                <img className="img-fluid" src={service1} alt="Best Animal Selection" />
                            </div>
                            <div className="service-text p-5 pt-0">
                                <div className="service-icon">
                                    <img className="img-fluid rounded-circle" src={service1} alt="Best Animal Selection" />
                                </div>
                                <h5 className="mb-3">Consultation and Design</h5>
                                <p><ul  className="mb-4"><li><b>Energy Needs Consultation: </b> Analyze consumption to find effective inverter solutions.</li></ul></p>
                                <p><ul  className="mb-4"><li><b>Custom System Design: </b> Tailored designs for maximum efficiency.</li></ul></p>

                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item d-flex h-100">
                            <div className="service-img">
                                <img className="img-fluid" src={service2} alt="Breeding & Veterinary" />
                            </div>
                            <div className="service-text p-5 pt-0">
                                <div className="service-icon">
                                    <img className="img-fluid rounded-circle" src={service2} alt="Breeding & Veterinary" />
                                </div>
                                <h5 className="mb-3">Battery Replacement Service</h5>
                                <p className="mb-4">Come in today to purchase a new or reconditioned battery & get it installed at the lowest price!</p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 pt-5 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="service-item d-flex h-100">
                            <div className="service-img">
                                <img className="img-fluid" src={service3} alt="Care & Milking" />
                            </div>
                            <div className="service-text p-5 pt-0">
                                <div className="service-icon">
                                    <img className="img-fluid rounded-circle" src={service3} alt="Care & Milking" />
                                </div>
                                <h5 className="mb-3">In Store Services</h5>
                                <p className="mb-4">At all locations, we offer many battery services as well as a stocking warehouse for all your battery needs</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;
