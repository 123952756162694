import React from 'react';

import inverter1 from '../img/inverter1.jpeg';
import inverter2 from '../img/inverter2.jpeg';
import inverter3 from '../img/inverter3.jpeg';
import inverter4 from '../img/inverter4.jpeg';
import inverter5 from '../img/inverter5.jpeg';
import inverter6 from '../img/inverter6.jpeg';
import inverter7 from '../img/inverter7.jpeg';
import inverter8 from '../img/inverter8.jpeg';
import inverter9 from '../img/inverter9.jpeg';
import inverter10 from '../img/inverter10.jpeg';
import inverter11 from '../img/inverter11.jpeg';
import inverter12 from '../img/inverter12.jpeg';
import inverter13 from '../img/inverter13.jpeg';
import inverter14 from '../img/inverter14.jpeg';
import inverter16 from '../img/inverter16.jpeg';
import inverter17 from '../img/inverter17.jpeg';



const Gallery = () => {
    const imageArray = [
        inverter1,
        inverter2,
        inverter3,
        inverter4,
        inverter5,
        inverter6,
        inverter7,
        inverter8,
        inverter9,
        inverter10,
        inverter11,
        inverter12,
        inverter13,
        inverter14,
        inverter16,
        inverter17,
    ];
    return (
        <div className="container-xxl py-5 px-0">
            <div className="row g-0">
            {imageArray.map((url, index) => (

                <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-12">
                        <a className="d-block" href={url} data-lightbox="gallery">
                            <img className="img-fluid" src={url} alt={`Image ${index + 1}`} />
                        </a>
                    </div>
                   
                </div>
            </div>
                
            ))}
               
                
            </div>
        </div>
    );
};

export default Gallery;
