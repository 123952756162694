import React from 'react';
import img1 from "../img/snpslogo1.png"
import img2 from "../img/batteryinverterbox.webp"


const ContactPageForm = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">Contact Us</p>
                    <h1 className="mb-5">If You Have Any Query, Please Contact Us</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <img style={{height: '330px',width:"auto"}} src={img1} alt="inverter-image" />
                        <img style={{height: '330px',width:"auto"}} src={img2} alt="inverter-image" />
                    </div>
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <h3 className="mb-4">Contact Details</h3>
                        <div className="d-flex border-bottom pb-3 mb-3">
                            <div className="flex-shrink-0 btn-square bg-secondary rounded-circle">
                                <i className="fa fa-map-marker-alt text-body"></i>
                            </div>
                            <div className="ms-3">
                                <h6>Our Office</h6>
                                <span>8/2, FCI Main Road Near Maremma Temple Channsandra, circle, Kadugodi, Bengaluru, Karnataka 560067</span>
                            </div>
                        </div>
                        <div className="d-flex border-bottom pb-3 mb-3">
                            <div className="flex-shrink-0 btn-square bg-secondary rounded-circle">
                                <i className="fa fa-phone-alt text-body"></i>
                            </div>
                            <div className="ms-3">
                                <h6>Call Us</h6>
                                <a href="tel:+919844123366">+91 9844123366</a>
                            </div>
                        </div>
                        <div className="d-flex border-bottom-0 pb-3 mb-3">
                            <div className="flex-shrink-0 btn-square bg-secondary rounded-circle">
                                <i className="fa fa-envelope text-body"></i>
                            </div>
                            <div className="ms-3">
                                <h6>Mail Us</h6>
                                <span>srinityapowersolutions@gmail.com</span>
                            </div>
                        </div>

                        <iframe
                            className="w-100 rounded"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.7843300179825!2d77.76511617507674!3d12.985641687330961!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae0f3e4900838f%3A0x1078a72a4f494051!2sSRI%20NITYA%20POWER%20SOLUTIONS%20(%20LUMINOUS%20EXIDE%20AMARON%20INVERTER%20BATTERY%20SHOP%20)%20SALES%20%26%20SERVICES!5e0!3m2!1sen!2sin!4v1728415269495!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                            frameBorder="0"
                            style={{ minHeight: '300px', border: '0' }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPageForm;
