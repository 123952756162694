import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/style.css'
import './styles/bootstrap.main.css'
import App from './App';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './lib/animate/animate.css'
import './lib/animate/animate.min.css'
import './lib/lightbox/css/lightbox.css'
import './lib/lightbox/css/lightbox.min.css'
import './scss/bootstrap.scss';  





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


