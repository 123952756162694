import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.sticky-top');
      if (window.scrollY > 300) {
        navbar.classList.add('shadow-sm');
        navbar.style.top = '0px';
      } else {
        navbar.classList.remove('shadow-sm');
        navbar.style.top = '-100px';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5">
      <NavLink to="/" className="navbar-brand d-flex align-items-center">
        <h1 className="m-0">SNPS</h1>
      </NavLink>
      <button
        type="button"
        className="navbar-toggler me-0"
        onClick={toggleMenu}
        aria-controls="navbarCollapse"
        aria-expanded={isOpen}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <NavLink to="/" className="nav-item nav-link" activeClassName="active" onClick={closeMenu}>Home</NavLink>
          <NavLink to="/about" className="nav-item nav-link" activeClassName="active" onClick={closeMenu}>About</NavLink>
          <NavLink to="/services" className="nav-item nav-link" activeClassName="active" onClick={closeMenu}>Services</NavLink>
          <NavLink to="/products" className="nav-item nav-link" activeClassName="active" onClick={closeMenu}>Products</NavLink>
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              role="button"
              onClick={toggleMenu}
              aria-expanded={isOpen}
            >
              Pages
            </a>
            <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
              <NavLink to="/gallery" className="dropdown-item" onClick={closeMenu}>Gallery</NavLink>
              <NavLink to="/features" className="dropdown-item" onClick={closeMenu}>Features</NavLink>
              <NavLink to="/testimonial" className="dropdown-item" onClick={closeMenu}>Testimonial</NavLink>
            </div>
          </div>
          <NavLink to="/contact" className="nav-item nav-link" activeClassName="active" onClick={closeMenu}>Contact</NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
