import React from 'react';
import service1 from '../img/aboutbg1.png';
import service2 from '../img/aboutusbg2.png';
import service3 from '../img/aboutus3.jpeg';
import service from '../img/service.png';
import product from '../img/product1.png';
import snpslogo from '../img/snpslogo1.png';



const About = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-end">
          <div className="col-lg-6">
            <div className="row g-2">
              <div className="col-6 position-relative wow fadeIn" data-wow-delay="0.7s">
                <div style={{backgroundColor:"blue"}} className="about-experience bg-secondary rounded">
                <img className="img-fluid rounded" src={snpslogo} alt="Service 3" />
                </div>
              </div>
              <div className="col-6 wow fadeIn" data-wow-delay="0.1s">
                <img style={{backgroundColor:"darkcyan"}} className="img-fluid rounded" src={service1} alt="Service 1" />
              </div>
              <div className="col-6 wow fadeIn" data-wow-delay="0.3s">
                <img style={{backgroundColor:"darkgrey"}} className="img-fluid rounded" src={service2} alt="Service 2" />
              </div>
              <div className="col-6 wow fadeIn" data-wow-delay="0.5s">
                <img className="img-fluid rounded" src={service3} alt="Service 3" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <p className="section-title bg-white text-start text-primary pe-3">About Us</p>
            <h1 className="mb-4">Know About Our Inverter & Battery Company</h1>
            <p className="mb-4">
            At Sri Nitya Power Solutions, we specialize in providing high-quality inverter solutions tailored to meet the unique energy needs of both residential and commercial clients. Our experienced team is dedicated to delivering exceptional service from consultation to installation and beyond.
            </p>
            <div className="row g-5 pt-2 mb-5">
              <div className="col-sm-6">
                <img className="img-fluid mb-4" src={product}  alt="Dedicated Services" />
                <h5 className="mb-3">Quality Products</h5> 
                <span>We offer only the most reliable brands and products to ensure your satisfaction.</span>
              </div>
              <div className="col-sm-6">
                <img className="img-fluid mb-4" src={service}  alt="Organic Products" />
                <h5 className="mb-3">Expert Advice</h5>
                <span>Our knowledgeable staff is here to help you navigate our extensive range of batteries and accessories.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
