import React from 'react';
import product1 from '../img/bikebattery.webp';
import product2 from '../img/carbattery.webp';
import product3 from '../img/truckbattery.webp';
import product4 from '../img/tractorbattery.webp';
import product5 from '../img/solarinverter.webp';
import product6 from '../img/tubularinverter.webp';
import product7 from '../img/sinewaveinverter.jpg';
import product8 from '../img/standbyups.png';

const Products = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="section-title bg-white text-center text-primary px-3">Our Products</p>
                    <h1 className="mb-5">Our Inverter & Battery Products for Reliable Power</h1>
                </div>
                <div className="row gx-4">
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product5} alt="Pure Milk" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Solar Inverter</a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product6} alt="Fresh Meat" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Tubular Inverter</a>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product7} alt="Dairy Products" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Sine Wave Inverter</a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product8} alt="Organic Food" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Standby UPS</a>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-4">
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product1} alt="Pure Milk" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Bike  Battery</a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product2} alt="Fresh Meat" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Car Battery</a>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product3} alt="Dairy Products" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Truck Battery</a>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div className="product-item">
                            <div className="position-relative">
                                <img className="img-fluid" src={product4} alt="Organic Food" />
                               
                            </div>
                            <div className="text-center p-4">
                                <a className="d-block h5" href="">Tractor Battery</a>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
