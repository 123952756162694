
import React, { useState, useEffect } from 'react';
import Spinner from '../components/Spinner'; 
import ContactPageHeader from '../components/ContactPageHeader';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../lib/animate/animate.css'
import '../lib/animate/animate.min.css'
import '../lib/lightbox/css/lightbox.css'
import '../lib/lightbox/css/lightbox.min.css'


function ContactPage() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
     
        const timer = setTimeout(() => {
            setLoading(false); 
        }, 2000);

        return () => clearTimeout(timer); 
    }, []);

    return (
        <div>
            {loading ? (
                <Spinner /> 
            ) : (
                <>
                    
                    <ContactPageHeader/>
                    
                </>
            )}
        </div>
    );
}

export default ContactPage;
